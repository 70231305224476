import { getByCourseSeriesId } from '@/states/couseSeries'
import { createCourseSeries } from 'momai'
import { computed, ref } from 'vue'

const activeTab = ref(0)

const detailCourseSeriesId = ref(-1)

const detailCourseSeries = computed(() => {
  const c = getByCourseSeriesId.value(Number(detailCourseSeriesId.value))
  if (c) {
    return c
  }
  return createCourseSeries()
})

const courseSeriesPrice = computed(() => detailCourseSeries.value.cashPrice)
const salePrice = computed(() => detailCourseSeries.value.salePrice)
const magicBeanPrice = computed(() => detailCourseSeries.value.magicBeanPrice)
export {
  detailCourseSeriesId,
  courseSeriesPrice,
  salePrice,
  magicBeanPrice,
  detailCourseSeries,
  activeTab
}
