
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { computed, defineAsyncComponent, ref } from 'vue'
import useFetchDetail from './useFetchDetail'
import useSendReview from './useSendReview'
import { usecanWatch } from '@/api/user/student'
import { detailCourseSeries } from '../course-list/state'

export default {
  components: {
    Introduction: defineAsyncComponent(() => import('./Introduction.vue')),
    Comments: defineAsyncComponent(() => import('./Comments.vue')),
    MyNote: defineAsyncComponent(() => import('./Note.vue'))
  },
  setup: () => {
    const route = useRoute()
    const { courseId } = route.params
    const { showCommentTag } = route.query
    const { courseDetail, reviewPageData } = useFetchDetail({ courseId })
    const activeTab = ref(showCommentTag === 'true' ? 1 : 0)
    const ready = ref(false)
    const { canWatchCourse, resetCanWatchState } = usecanWatch(Number(courseId), () => {
      setTimeout(() => {
        ready.value = true
      }, 500)
    })
    const showPay = computed(() => !canWatchCourse.value && detailCourseSeries.value.type === 0)
    onBeforeRouteLeave(() => {
      ready.value = false
    })
    return {
      ...useSendReview({ courseId }),
      ready,
      showPay,
      canWatchCourse,
      resetCanWatchState,
      detailCourseSeries,
      reviewPageData,
      courseDetail,
      activeTab,
      courseId
    }
  }
}
