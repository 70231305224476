import { Course, createModuleApi, OrderInfo, Page, PageResponse, Review, ScoleDetailWithAllInfo } from 'momai'
import { ref } from 'vue'
import { CourseSeriesWithCourses, CourseWithHomewors } from './types'

const studentApi = <T>() => createModuleApi<T>('student')

/**
 * 未提交过作业的课程数量
 */
const unsubmittedHomeworkCourseNum = () => studentApi<number>()({
  subUrl: 'unsubmitted-homework-course-num'
})

/**
 * 未提交过作业的课程列表
 */
const unsubmittedHomeworkCourseList = () => studentApi<CourseSeriesWithCourses<Course>[]>()({
  subUrl: 'unsubmitted-homework-course-list'
})

/**
 * 获取我的已支付订单数量
 */
const myUnpaidOrderNum = () => studentApi<number>()({
  subUrl: 'my-paid-order-num'
})

/**
 * 分页查询我的已支付订单列表
 * @param p
 */
const pageMyPaidOrderList = (p: Page) => studentApi<PageResponse<OrderInfo>>()({
  subUrl: 'page-my-paid-order',
  method: 'post',
  params: p
})

/**
 * 查询学生的已提交作业数量，不论导师是否审核过，都算到最后的结果中
 */
const submittedHomewrokNum = () => studentApi<number>()({
  subUrl: 'sumitted-homework-num'
})

/**
 * 2021/2/4
 * 获取所有人对我的评论数量，只有我发布的公开的且已经经过导师审核过后的作业的评论
 */
const commentsToMeNum = () => studentApi<number>()({
  subUrl: 'comments-to-me-num'
})

/**
 * 分页获取他人对用户的评论列表，用户作业必须公开，且审核通过，评论也必须审核通过
 * @param page
 */
const pageCommentsToMe = (page: Page) => studentApi<PageResponse<Review>>()({
  subUrl: 'page-comments-to-me',
  method: 'post',
  params: page
})

/**
 * 按照课程系列 -> 课程 -> 作业 树形结构返回我的已完成作业列表
 */
const myDoneHomeworks = () => studentApi<CourseSeriesWithCourses<CourseWithHomewors>[]>()({
  subUrl: 'my-done-homeworks'
})

/**
 * 判断用户是否可以观看某个课程
 * @param courseId
 */
const canWatch = (courseId: number) => studentApi<boolean>()({
  subUrl: `can-watch/${courseId}`
})

/**
 * 用户是否可以对某个课程提交作业
 * @param courseId
 */
const canSubmitHomework = (courseId: number) => studentApi<boolean>()({
  subUrl: `can-submit-homework/${courseId}`
})

const hasBoughtCourseSeries = (courseSeriesId: number) => studentApi<boolean>()({
  subUrl: `has-bought-course-series/${courseSeriesId}`
})

const useHasBoughtCourseSeries = (courseSeriesId: number) => {
  const hasBought = ref(false)
  const resetHasBought = () => {
    hasBoughtCourseSeries(courseSeriesId).then(r => {
      hasBought.value = r
    })
  }
  resetHasBought()
  return {
    resetHasBought,
    hasBought
  }
}

const usecanWatch = (courseId: number, cb = () => {}) => {
  const canWatchCourse = ref(false)
  const resetCanWatchState = () => {
    canWatch(courseId).then(r => {
      canWatchCourse.value = r
    })
    cb()
  }
  resetCanWatchState()
  return {
    canWatchCourse,
    resetCanWatchState
  }
}

const useCanSubmitHomework = (courseId: number, cb = () => {}) => {
  const userCantSubmitHomework = ref(false)
  const resetSumitStatus = () => {
    canSubmitHomework(courseId).then(r => {
      userCantSubmitHomework.value = r
      cb()
    })
  }
  resetSumitStatus()
  return {
    resetSumitStatus,
    userCantSubmitHomework
  }
}

/**
 * 将某个订单状态改为已浏览状态
 * @param orderId 订单ID
 */
const checkOrder = (orderId: string) => studentApi<boolean>()({
  subUrl: `check-order/${orderId}`
})

/**
 * 获取用户已经提交过作业的课程数量
 */
const submittedCourseNum = () => studentApi<number>()({
  subUrl: 'submitted-course-num'
})

/**
 * 分页查询我的魔豆明细
 * @param params
 */
const pageScoreDetail = (params: Page) => studentApi<PageResponse<ScoleDetailWithAllInfo>>()({
  subUrl: 'page-score-detail',
  method: 'post',
  params
})

/**
 * 我的未读魔豆明细数量
 */
const unreadScoreDetailNum = () => studentApi<number>()({
  subUrl: 'unread-score-detail-num'
})

/**
 * 将某条魔豆明细设置为已读
 * @param id 魔豆明细ID
 */
const checkScoreDetail = (id: number) => studentApi<number>()({
  subUrl: `check-score-detail/${id}`
})

export {
  checkScoreDetail,
  pageScoreDetail,
  submittedCourseNum,
  checkOrder,
  useHasBoughtCourseSeries,
  useCanSubmitHomework,
  usecanWatch,
  myDoneHomeworks,
  pageCommentsToMe,
  commentsToMeNum,
  submittedHomewrokNum,
  pageMyPaidOrderList,
  myUnpaidOrderNum,
  unsubmittedHomeworkCourseNum,
  unsubmittedHomeworkCourseList,
  unreadScoreDetailNum,
  hasBoughtCourseSeries
}
