import { ref, reactive } from 'vue'
import { api } from '@/api/useAxios'
import { CourseDetail, Page, createCourseDetail } from 'momai'
import { changeShareInfo } from '@/utils/wechat'
import { detailCourseSeriesId } from '../course-list/state'
import { getUserId } from '@/utils/auth'

export default ({ courseId }: {
  courseId: string | string[];
}) => {
  const courseDetail = ref<CourseDetail>(createCourseDetail())
  const reviewPageData = reactive<Page>({
    current: 1,
    size: 10
  })

  const fetchDetail = () => {
    api.post('/courseinfo/info/detail', {
      ...reviewPageData,
      courseInfoId: courseId
    }).then(r => {
      courseDetail.value = r
      const c = courseDetail.value
      changeShareInfo({
        title: c.name,
        desc: c.desc,
        link: `${process.env.VUE_APP_REDIRECT_URI}/course-series/course-detail/${courseId}?referrer=${getUserId()}&`,
        imgUrl: c.img
      })
      detailCourseSeriesId.value = r.courseId
    })
  }

  fetchDetail()

  return {
    courseDetail,
    reviewPageData,
    fetchDetail
  }
}
